/*
 * Default media queries.
 *
 * @media (min-width: 480px) {
 *   // Styles for screens with a minimum width of 480px.
 * }
 *
 * @media (min-width: 768px) {
 *   // Styles for screens with a minimum width of 768px.
 * }
 *
 * @media (min-width: 1024px) {
 *   // Styles for screens with a minimum width of 1024px.
 * }
*/

.hero-grid {
  /* Overriding unwanted MUI styles */
  margin-top: 30px !important;
  padding-left: 20px;
  padding-right: 10px;
  margin-left: 0 !important;
  width: 100% !important;

  @media (min-width: 768px) {
    padding-left: 40px;
    margin-top: 60px !important;
  }
}

.hero-grid-item {
  /* Overriding unwanted MUI styles */
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.hero-paragraph {
  color: #fff;
  font-weight: 500;
  line-height: 1.5;
}

.hero-image {

}
