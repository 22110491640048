/* ContactDrawer.module.css */
.drawerPaper {
  height: var(--drawer-height);
  background-color: #f6faff;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
}

.grabItemBox {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background-color: black;
  color: white;
  align-items: center;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container {
  padding: 16px;
}

.poweredByBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.icons {
  height: 18px;
  transform: translateX(-10px);
}

.contactInfo {
  color: black;
  padding: 6px;
  max-width: 90%;
}

.buttonForm {
  border-radius: 30px !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  padding: 14px !important;
  font-size: 12px !important;
}

.divText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 90%;
}

.divInfoText {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
