/*
 * Default media queries.
 *
 * @media (min-width: 480px) {
 *   // Styles for screens with a minimum width of 480px.
 * }
 *
 * @media (min-width: 768px) {
 *   // Styles for screens with a minimum width of 768px.
 * }
 *
 * @media (min-width: 1024px) {
 *   // Styles for screens with a minimum width of 1024px.
 * }
*/

.heroLogo {
  max-height: 30px !important;
}

.navigation {
  border-radius: 1000px !important;
  padding: 8px 8px 8px 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    padding: 16px 16px 16px 32px;
  }
}

.nav-buttons {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
