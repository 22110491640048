.caption {
  position: absolute;
  top: -10px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.25);
}

.color-picker {
  margin-top: 10px;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.176);
}
