
/* COPY LINK  */

.container {
  margin-top: 8px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  border-radius: 40px !important;
  width: 100% !important;
  height: 30px !important;
  border: dashed 2px #247dff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  background-color: white !important;
}

.logoMeraviglia {
  width: 24px;
  height: auto;
}

.ellipsisText {
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* OPTION */

.optionContainer {
  background-color: rgba(36, 125, 255, 0.03);
  height: 100vh;
  justify-content: center !important;
}

.backBox {
  position: absolute !important;
  top: 15px !important;
  left: 20px !important;
  z-index: px !important;
}

.backIcon {
  width: 100%;
  display: flex;
  justify-content: left;
}

.optionMainContainer {
  padding: 16px;
  padding-top: 40px;
  min-height: 80%;
  overflow: auto;
}

.headerText {
  width: 100%;
  text-align: center;
  color: black;
  margin-top: 2;
  margin-bottom: 1;
}

.listItems {
  width: 100% !important;
  max-height: 55vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-bottom: 25px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column !important;
  transform: translateY(20px);
}

.itemList {
  border-radius: 40px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
}

.arrowIcon {
  height: 20px;
  transform: translate(0, 2px);
}

.itemIcon {
  height: 20px;
  filter: brightness(1) invert(0);
}

.boxCopyLink {
  height: 100%;
  flex-grow: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerCard {
  background-color: black !important;
  width: 100% !important;
  color: white !important;
  min-height: 150px !important;
  height: 20% !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 20px 20px 0 0 !important;
  display: flex !important;
  flex-direction: column !important;
}

.boxText {
  flex-grow: 1px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.imgMeraviglia {
  margin-left: 8px;
  height: 50px;
  width: auto;
}

.icon {
  height: 20px;
}
