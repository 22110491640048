.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 700px !important;
  padding: 30px !important;
}

.buttonForm {
  border-radius: 30px !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  padding: 14px !important;
}
