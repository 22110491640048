
.container {
    position: relative;
    align-self: end;
}

.itemMenu {
    margin-left: 8px !important;
    margin-right: 8px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 40px !important;
}

.icon {
height: 20px;
width: auto;
}
