.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 750px !important;
  padding: 30px !important;
}

.buttonForm {
  border-radius: 40px !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  padding: 14px !important;
  width: 100% !important;
}

.card {
  padding-bottom: 16px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border: solid 1px #f2f2f2 !important;
  border-radius: 24px !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
}

.icon {
  height: 20px;
  filter: invert(80%);
  margin: 15px;
}
