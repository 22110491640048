
.categories {
  margin-top: 10px !important;
  border: dotted 2px #b3b3b3 !important;
  border-radius: 40px !important;
  width: 80% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  color: black !important;
  padding: 16px !important;
}

.centerContainer {
display: flex !important;
justify-content: center !important;
align-items: center !important;
flex-direction: row !important;
}

.iconCategory {
  height: 20px !important;
  width: auto !important;
  margin-right: 8px !important;
  filter: brightness(0) invert(0) !important;
}
