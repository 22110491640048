.containerStep2 {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 500px !important;
  padding: 30px !important;
}

.containerStep1 {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 600px !important;
  padding: 30px !important;
}

.buttonForm {
  border-radius: 30px !important;
  margin-top: 24px !important;
  margin-bottom: 16px !important;
  padding: 14px !important;
  width: 100%;
}

.boxPhoto {
  width: 56px;
  height: 56px;
  border-radius: 50;
  margin-top: 16px;
  margin-bottom: 16px;
  background-size: cover;
  background-position: center;
}
