.container {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh !important;
}
.mainContainer {
  flex: 2 !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  display: flex !important;
}

.formGroup {
  height: 100% !important;
  display: flex !important;
  width: 100% !important;
  flex: 2 !important;
}

.centerContainer {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: row !important;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formButton {
  width: 100% !important;
  border-radius: 50px !important;
  padding: 14px !important;
  margin: 16px !important;
}

.uploadBox {
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.uploadButton {
  border-radius: 40px !important;
  padding: 14px !important;
  width: 100% !important;
}

.contactMenuItem {
  border-radius: 40px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.addButton {
  border-radius: 20px !important;
  width: 100% !important;
  height: 30px !important;
  font-size: 18px !important;
  position: relative !important;
  bottom: 0px !important;
  border: dashed 2px #247dff !important;
  background-color: rgba(36, 125, 255, 0.03) !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.addIcon {
  color: #247dff;
}

.icon {
  height: 20px;
  margin: 10px;
}

.iconTrash {
  height: 20px;
}

.iconTitle {
  height: 26px;
  margin: 10px;
}
