/* GRABITEM */

.dragableContainer {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  max-width: 70px !important;
}

.dividerStyle {
  min-width: 35px;
  height: 5px;
  border-radius: 10px;
  background-color: #c4c4c4;
}
