.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  max-height: 800px !important;
  min-height: 700px !important;
  padding: 24px !important;
}

.boxVideo {
  width: 100% !important;
  height: 100% !important;
  max-height: 450px !important;
  max-width: 450px !important;
}

.videoWrapper {
  position: relative !important;
  width: 100% !important;
  padding-bottom: 100% !important;
  overflow: hidden !important;
}

.video {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 30px !important;
  border: #f5f5f5 4px solid !important;
}

.button {
  border-radius: 40px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  width: 100% !important;
}

.mainBox {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin: 24px !important;
}
