.drawerPaper {
  border-radius: 20px 20px 0px 0px;
  box-shadow: none;
  height: drawerHeight;
  max-height: 800px;
  width: 100%;
  overflow-y: auto;
  background-color: "#F2F2F2";
}

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.categories {
  margin-top: 10px !important;
  border: dotted 2px #b3b3b3 !important;
  border-radius: 40px !important;
  width: 80% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  color: black !important;
  padding: 16px !important;
}

.skeletonItem {
  margin-top: 10px !important;
  border: dotted 2px #b3b3b3 !important;
  border-radius: 40px !important;
  width: 80% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  padding: 16px !important;
}

.iconCategory {
  height: 20px !important;
  width: auto !important;
  margin-right: 15px !important;
  filter: brightness(0) invert(0) !important;
}

.centerContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
}
