.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formOAuth {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px !important;
}

.icons {
  height: auto;
  width: 24px;
}

.defaultGoogleButton {
  margin-top: 25px;
}
