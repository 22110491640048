.formControlPassword {
  margin-bottom: 13px !important;
  width: 100%;
}

.iconEye {
  height: 20px;
  filter: invert(80%);
}

.icon {
  height: 20px;
  filter: invert(80%);
  margin: 8px;
  margin-right: 10px;
}
