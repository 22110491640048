.button {
  border-radius: 40px !important;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  padding: 16px !important;
  width: 100% !important;
}

.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 750 !important;
  padding: 30px !important;
  padding-top: 45px !important;
}

.boxBack {
  position: absolute;
  top: 40px;
  left: 25px;
}

.iconBack {
  width: 100%;
  display: flex;
  justify-content: left;
}
