.container {
  overflow: auto !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.avatarContainer {
  width: 140px !important;
  height: 140px !important;
  overflow: hidden !important;
  margin: 5px !important;
  flex-shrink: 0;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}

.imgIcons {
  height: 22px;
  filter: brightness(0) invert(0);
}

.containerMain {
  text-align: center !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column !important;
  max-height: 55% !important;
}

.buttonStyle {
  border-radius: 30px !important;
  min-width: 235px !important;
  width: 71.5% !important;
  height: 50px !important;
  font-size: 16px !important;
  background-color: #f5f5f5 !important;
  margin: 5px !important;
  color: black !important;
  text-transform: none !important;
  justify-content: center !important;
  border: none !important;
}

.imgIcons {
  height: 19px;
  filter: brightness(0) invert(0);
}
.iconsLink {
  height: 21px;
  filter: brightness(0) invert(0);
}

.avatarBox {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #f5f5f5 !important;
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.avatar {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  object-fit: cover;
}

.editicon {
  width: auto !important;
  height: 20px !important;
}

.boxCopy {
  width: 100% !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.rightcornerIcon {
  position: absolute;
  top: 25px;
  right: 15px;
}

.leftcornerIcon {
  position: absolute;
  top: 25px;
  left: 15px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mainBox {
  margin-bottom: 8px;
  width: 100%;
  overflow-y: auto;
}
