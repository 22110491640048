.box {
  position: relative;
  background-color: #f2f2f2;
  width: 100%;
  padding: 30px;
  padding-top: 16px !important;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.caption {
  position: absolute;
  top: -15px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: bold !important;
  color: rgba(0, 0, 0, 0.25);
}

.color-box-grid {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.color-box {
  width: 100px;
  height: 180px;
  border-radius: 7px;
  cursor: pointer;
}

.selected {
  border: 4px solid #f2f2f2;
  outline: 2px solid #bfbfbf;
}

.color-box-caption {
  font-weight: bold !important;
  font-size: 13px;
  color: #374957;
}

.container-button {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.reset-button {
  border-radius: 40px !important;
  padding: 4px 20px !important;
}
