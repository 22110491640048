.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  min-height: 750px !important;
  padding: 30px !important;
  padding-top: 45px !important;
}

.boxBack {
  position: absolute;
  top: 40px;
  left: 25px;
}

.iconBack {
  width: 100%;
  display: flex;
  justify-content: left;
}

.avatarBox {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #f5f5f5 !important;
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.avatar {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  object-fit: cover;
  pointer-events: none;
}

.button {
  border-radius: 40px !important;
  margin-top: 16px !important;
  padding: 16px !important;
  width: 100% !important;
}

.form {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.editicon{
  width: auto !important;
  height: 20px !important;
}

.dialog-button {
  border-radius: 40px !important;
  padding: 4px 20px !important;
  margin: 8px !important;
}
