.button {
  border-radius: 40px !important;
  padding: 8px 32px !important;
}

.content {
  position: relative !important;
  height: 400px !important;
  width: auto !important;
  border-radius: 32px !important;
}

.color-picker {
  margin-top: 10px;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.176);
}
