.containerMeraviglia {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.containerFooter {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img {
  margin-left: 8px;
  height: 18px;
  width: auto;
}
