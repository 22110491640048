/* BottomDrawer.module.css */
.drawerPaper {
  height: var(--drawer-height);
  background-color: #f6faff;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
}

.grabItemBox {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background-color: black;
  color: white;
  align-items: center;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container {
  padding: 16px;
}

.listItem {
  border-radius: 30px !important;
  color: #000 !important;
}

.listItemIcon {
  min-width: 30px;
  filter: brightness(0);
}

.imgIcon {
  height: 20px;
}

.secondaryActionIcon {
  height: 20px;
  transform: translate(0, 2px);
  filter: brightness(0);
}

.poweredByBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
