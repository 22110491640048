.text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  white-space: pre-wrap;
}

.circleErrorIcon {
  font-size: 1rem;
  margin-right: 4px;
  margin-bottom: 4px;
}
