.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  min-height: 750px !important;
  padding: 30px !important;
}

/* STEP 1 */
.boxSkip {
  position: absolute;
  top: 40px;
  right: 25px;
}

.skip {
  width: 100%;
  text-align: right;
}

.mainBox {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button {
  border-radius: 40px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  width: 100% !important;
}

/* STEP 2 */
.boxBack {
  position: absolute;
  top: 40px;
  left: 25px;
}

.iconBack {
  width: 100%;
  display: flex;
  justify-content: left;
}

.imageBoxS2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #f5f5f5;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

/* STEP 3 */

.imageBoxS3 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  background-color: #f5f5f5;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
}

.containerGrid {
  width: 100%;
  direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.form {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
