/* ButtonsMenu.module.css */
.container {
  text-align: center !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column !important;
  max-height: 55% !important;
}

.buttonsBox {
  margin-bottom: 8px;
  width: 100%;
  overflow: auto;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.buttonStyle {
  border-radius: 30px !important;
  min-width: 235px !important;
  width: 71.5% !important;
  height: 50px !important;
  font-size: 16px !important;
  background-color: #f5f5f5 !important;
  margin: 6px !important;
  color: black !important;
  text-transform: none !important;
  /* justify-content: space-between !important; */
  border: none !important;
}


.imgIcons {
  height: 19px;
  filter: brightness(0) invert(0);
}

.divIcon {
  padding: 12px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.iconNormal {
  height: 21px;
  filter: brightness(0) invert(0);
}

.iconDimmed {
  height: 21px;
  filter: brightness(0.2) invert(0.8);
}

.boxStyleOnDrag {
  background-color: #f2f2f2;
}

.addButton {
  border-radius: 40px !important;
  width: 70% !important;
  height: 50px !important;
  font-size: 20px !important;
  background-color: rgba(36, 125, 255, 0.03) !important;
  border: dashed 2px rgba(36, 125, 255, 0.44) !important;
  position: relative !important;
  bottom: 0 !important;
}

.addIcon {
  color: rgba(36, 125, 255, 0.44);
  background-color: transparent;
}

.centerGrid {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.iconsLink {
  height: 21px;
  filter: brightness(0) invert(0);
}

.gridContainer {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
  max-width: 150px !important;
  margin-bottom: 16px !important;
  margin-top: 8px !important;
}
