.formControlEmail {
  margin-top: 24px !important;
  margin-bottom: 13px !important;
  width: 100%;
}

.icon {
  height: 20px;
  filter: invert(80%);
  margin: 8px;
  margin-right: 10px;
}

.helpertextEmail {
  padding-top: 4px;
  padding-left: 30px;
}
