body {
  font-family: "Poppins";
  height: 100%;
  background-color: #ffffff;
}

/* Overriding user agent autocomplete style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}
