.container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
  min-height: 750px !important;
  padding: 30px !important;
}

.boxSkip {
  position: absolute;
  top: 40px;
  right: 25px;
}

.skip {
  width: 100%;
  text-align: right;
}

.avatarBox {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  height: 300px;
  width: 300px;
}

.avatar {
  width: 300px !important;
  height: 300px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.button {
  border-radius: 40px !important;
  margin-bottom: 16px !important;
  padding: 16px !important;
  width: 100% !important;
  margin-top: 16px !important;
}

.icon {
  height: 22px !important;
}

.drawerContainer {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 50px !important;
  padding: 30px !important;
}

.cameraBox {
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  border: solid 2px #f5f5f5;
  border-radius: 20px;
  padding: 16px;
}

.camera {
  border-radius: 50%;
  height: 300px;
  width: 300px;
  object-fit: cover;
  margin: 16px;
}

.centerBox {
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}

.drawerPaper {
  border-radius: 20px 20px 0px 0px;
  box-shadow: none;
  height: drawerHeight;
  max-height: 800px;
  width: 100%;
  overflow-y: auto;
  background-color: "#F2F2F2";
}

.buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
