/* HeaderStyles.css */

.container {
  padding: 16px !important;
  width: 100% !important;
}

.flexSpaceBetween {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.gridSelects {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row;
}

.gridSelects {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row;
}

.titleTypography {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.formControl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridSelectsContainer {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-bottom: 30px !important;
}

.selectLabel {
  margin-left: 8px;
}

.selectStyle {
  padding-left: 3px !important;
  border-radius: 40px !important;
}

.menuItemStyle {
  border-radius: 40px !important;
  margin: 5px !important;
  min-width: 170px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  padding-right: 10px;
}

.menuItemStyle img {
  width: 20px;
  margin-right: 14px;
  flex-shrink: 0;
}

.itemMenuText {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paperStyle {
  border: solid 3px #f2f2f2 !important;
  padding: 16px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  border-radius: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
